.container.contact__container {
  display: flex;
}

.contact__options {
  display: grid;
  flex-direction: column;
  gap: 1.2rem;
}

.contact__option {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
}
.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--color-white);
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

.contact__option h5 {
  color: var(--color-white);
}

.contact__option h4 {
  color: var(--color-white);
}

.contact__option small {
  color: var(--color-white);
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-white);
  resize: none;
  color: var(--color-white);
}

::placeholder {
  color: white;
}

.contact__form {
  width: 60%;
  padding-left: 6%;
}

.contact__form textarea {
  height: 300px;
}

.btn {
  color: var(--color-primary);
}

@media screen and (max-width: 1024px) {
  .container.contact__container {
    flex-direction: column;
    gap: 2rem;
  }
  .contact__form {
    padding-left: 0%;
    width: auto;
  }
}

@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }
}
