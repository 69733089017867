header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.header__bio {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.header__bio-content {
  width: 50%;
  margin-left: 0.5rem;
}

/* ======================= CTA ======================= */

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.btn {
  margin-top: auto;
  background: #d83f87;
}

/* ================ Header Socials ==============*/

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: fixed;
  left: 2rem;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-white);
}

/*=====================IMAGE=================*/

.me {
  width: 50%;
  display: flex;
  justify-content: center;
  height: 30rem;
  margin-right: 0.5rem;
}

.me img {
  border-radius: 3rem;
}

/* ================== MEDIA QUERIES (MEDIUM DEVICES) =========*/
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
    min-height: 110vh;
  }
  .header__bio {
    grid-template-columns: 1fr;
    font-size: 1.5vh;
  }
}

@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }

  .header__socials {
    display: none;
  }
}
